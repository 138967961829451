<template>
  <section class="login-form">
    <div
      class="login-btn primary-c"
      @click="$router.push({name: 'login',query: {phone: form.phone}})"
    >
      登陆
    </div>
    <!-- logo -->
    <div class="login-logo">
      <img
        class="login-logo__pic"
        src="../images/logo.svg"
        alt="儿童成长检测"
      >
    </div>

    <!-- form表单 -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      class="form"
    >
      <div class="form-title">
        找回密码
      </div>
      <el-form-item
        prop="phone"
        class="form-item"
      >
        <PhoneInput
          v-model="form.phone"
          :phoneType.sync="form.phoneType"
        />
      </el-form-item>

      <el-form-item
        prop="code"
        class="form-item"
      >
        <el-input
          v-model="form.smsCode"
          class="code-input"
          placeholder="请输入验证码"
          type="text"
          autocomplete="off"
        >
          <el-button
            slot="suffix"
            type="text"
            :disabled="!!countDownTime||isGetSmsCode"
            class="code-btn"
            :class="{'primary':!countDownTime}"
            @click="getCode"
          >
            <template v-if="countDownTime">
              {{ countDownTime }}秒重新获取
            </template>
            <template v-else>
              获取验证码
            </template>
          </el-button>
        </el-input>
      </el-form-item>

      <el-form-item
        prop="password"
        class="form-item"
      >
        <el-input
          v-model="form.password"
          placeholder="请输入新密码"
          type="password"
          autocomplete="off"
        />
      </el-form-item>

      <el-form-item
        prop="confirmPassword"
        class="form-item"
      >
        <el-input
          v-model="form.confirmPassword"
          placeholder="请输入新密码"
          type="password"
          autocomplete="off"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          :disabled="disabledSubmit"
          class="form-btn"
          type="primary"
          round
          @click="submitForm"
        >
          确认
        </el-button>
      </el-form-item>
      <el-form-item class="form-item">
        <div class="form-agree">
          登录代表我已阅读并同意 <a
            href="#"
            class="primary-c"
          >儿科主任医生集团服务协议</a> 和 <a
            href="#"
            class="primary-c"
          >隐私政策</a>
        </div>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import md5 from 'js-md5';
import { getSmsCode, changePassword } from '@/api/login';

export default {
  components: {
    PhoneInput: () => import('./phoneInput.vue')
  },
  data() {
    const validphone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value.replace(/\s/g, ''))) {
        callback(new Error('请输入正确的手机号!'));
      } else {
        callback();
      }
    };
    const validPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空！'));
      } else {
        callback();
      }
    };
    const validConfirmPassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致！'));
      } else {
        callback();
      }
    };
    return {
      form: {
        phone: '',
        phoneType: '1',
        smsCode: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        phone: [
          { validator: validphone, trigger: 'change' }
        ],
        smsCode: [
          { required: true, trigger: 'change' }
        ],
        password: [
          { validator: validPassword, trigger: 'change' }
        ],
        confirmPassword: [
          { validator: validConfirmPassword, trigger: 'change' }
        ],
      },
      countDownTime: null,
      isSubmit: false,
      isGetSmsCode: false
    };
  },
  computed: {
    disabledSubmit() {
      return !this.form.phone || !this.form.smsCode || !this.form.password;
    }
  },
  created() {
    this.form.phone = this.$route.query.phone;
  },
  methods: {
    // 获取短信验证码
    getCode() {
      this.isGetSmsCode = true;
      this.$refs.form.validateField('phone', (errMessage) => {
        if (errMessage) {
          this.isGetSmsCode = false;
          return;
        }
        this.getSmsCode();
      });
    },
    getSmsCode() {
      this.countDown(60);

      const params = {
        phone: this.form.phone.replace(/\s/g, ''),
        type: 'LOGIN'
      };
      getSmsCode(params).then((res) => {
        this.$message.success(res.msg || '验证码已发送！');
      }, (err) => {
        this.$message.warning(err.msg);
      }).finally(() => {
        this.isGetSmsCode = false;
      });
    },
    // 倒计时
    countDown(time) {
      if (typeof time === 'number') {
        this.countDownTime = time;
      } else if (this.countDownTime > 0) {
        this.countDownTime -= 1;
      }
      if (this.countDownTime > 0) {
        setTimeout(this.countDown, 1000);
      }
    },
    // 提交修改
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.changePassword();
      });
    },
    // 修改密码
    changePassword() {
      const password = md5(this.form.password);
      const confirmPassword = md5(this.form.confirmPassword);

      const params = {
        confirmPassword,
        password,
        phone: this.form.phone,
        smsCode: this.form.smsCode,
        type: 'ORG'
      };

      changePassword(params).then((res) => {
        this.$message.success(res.msg || '修改成功！');

        setTimeout(() => {
          this.$router.replace({
            name: 'login',
            query: {
              phone: this.form.phone
            }
          });
        }, 1500);
      }, (err) => {
        this.$message.warning(err.message || '修改失败！');
      });
    }
  }
};
</script>
<style lang="less" scoped>
@primary: #5FB4BF;
.login-form{
    box-sizing: border-box;
    width: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #FFF;
    border-radius: 8px;
    padding: 40px 50px 16px;
    z-index: 9;
    .login-btn{
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 16px;
      cursor: pointer;
    }
}
// logo
.login-logo{
  text-align: center;
  &__pic{
    width: 210px;
  }
}
// 表单
.form {
  /deep/ .el-input__inner{
    border-radius: 40px;
    border: 1px solid #E5E5E5;
    font-weight: normal;
    font-size: 16px;
    color: #000;
  }

  .form-title{
    font-weight: 500;
    font-size: 16px;
    color: #333;
    padding-left: 4px;
    margin-top: 19px;
    margin-bottom: 16px;
  }

  .form-item{
    margin-bottom: 22px;
  }

  .code-input{
    /deep/ .el-input__inner{
      padding-right: 150px;
    }
  }

  .code-btn{
    padding-right: 16px;
    font-size: 16px;
    font-weight: 400px;
    &.primary{
      color: @primary;
      &:active{
        color: darken(@primary,10%);
      }
    }
  }

  .form-btn{
    display: block;
    width: 100%;
    background: linear-gradient(270deg, #47C2C2 2.64%, #78DBD9 100%);
    border:none;
    /deep/ span{
      color: #fff !important;
    }
    &:disabled{
      opacity: 0.5;
    }
  }

  .form-agree{
    white-space: nowrap;
    &>a{
      text-decoration: auto;
    }
  }
}
</style>
